import React from 'react'

import WebsiteFooter from '../components/WebsiteFooter'
import WebsiteHeader from '../components/WebsiteHeader'
import SEO from '../components/SEO'
import shareImage from '../images/sync_share_image.png'
import { getAuthState } from '../utils/auth'
const TermsAndPrivacy = () => {

  const authState = getAuthState()

return (
  <div className="flex flex-col justify-center">
    <SEO
        title="Privacy Policy &amp; Terms of Use"
        description="By using our services, you are agreeing to the following terms of use. Do not sign up or use calendarbridge.Com if you do not agree to these terms."
        image={shareImage}
      />
  <div className="bg-brand_dark_blue grid grid-cols-1">
    <WebsiteHeader theme="dark" authState={authState}></WebsiteHeader>
    </div>
    <div className="flex mt-4 p-4 lg:p-0 bg-white justify-center self-center" style={{maxWidth:1200, width: '100%'}}>
      <div style={{ width: '100%', padding: '0.5rem' }}>
    <h2>TERMS OF USE AND PRIVACY POLICY</h2>
    <br />
    <br />
    <h3>
      BY USING OUR SERVICES, YOU ARE AGREEING TO THE FOLLOWING TERMS OF USE (the
      “AGREEMENT” or “TERMS”). Do not sign up or use CalendarBridge.com if you
      do not agree to these Terms.
    </h3>
    <br />
    Effective date: Aug 27, 2020 12:00CST
    <br />
    <br />
    <b>Foreword</b>
    <br />
    <br />
    Below are the rules and restrictions that govern your use of our website(s),
    products, services, and applications (the “Services”). If you have any
    questions, comments, or concerns regarding these terms or the Services,
    please contact us at info@calendarbridge.com. You will be bound by these
    terms, so you should read them. That said, you are probably at this page
    seeking the answer to one question:
    <br />
    <br />
    What does CalendarBridge do with your calendar data?
    <br />
    <br />
    <b>
      <em>
        The only thing we do with your calendar data is copy it between your
        calendars as instructed by the configuration of your syncs. We do not
        store or share any information about the content of your calendars. We
        only store unique identifiers for calendar events so that we can
        retrieve them from Google and Microsoft and keep track of what events
        are copies, etc. The exception to this is if you use the scheduling
        service. Specifically, when someone books an appointment via a
        scheduling link, an email is sent to you with the details they entered.
        Those emails are stored on the server of our email sending service
        (currently Postmark).
      </em>
    </b>{' '}
    <br />
    <br />
    So with that out of the way, onto the legalese...
    <br />
    <br />
    <b>Changes to these Terms</b>
    <br />
    <br />
    These Terms of Use (the “Terms”) are a binding contract between you and
    CalendarBridge Inc.. (“CalendarBridge,” “we” and “us”). You must agree to
    and accept all of the Terms, or you don't have the right to use the
    Services. Your use of the Services in any way means that you agree to all of
    these Terms, and these Terms will remain in effect while you use the
    Services.
    <br />
    <br />
    Occasionally it may be necessary to change these Terms due to changes in the
    Services or the law. Accordingly, we reserve the right to change the Terms
    at any time. If the terms are changed, we will bring it to your attention by
    placing a notice on our website, sending you an email, and/or by some other
    means. If you don't agree with the changes, you will not be able to continue
    using the Services.
    <br />
    <br />
    Except for changes by us as described here, no other amendment or
    modification of these Terms will be effective unless in writing and signed
    by both you and us.
    <br />
    <br />
    <b>Your Use of the Services</b>
    <br />
    <br />
    To use our Services you will be required to provide an email address for
    user as your CalendarBridge User ID. You will also have to connect your
    CalendarBridge account to one or more third-party accounts (e.g. Google,
    Microsoft, etc.) to use the Services (“Connected Accounts”). You hereby
    authorize CalendarBridge to receive and/or access all information from your
    Connected Accounts necessary for CalendarBridge to provide the functionality
    of the Services.
    <br />
    <br />
    You represent and warrant that you are an individual of legal age to form a
    binding contract (or if not, you've received your parent's or guardian's
    permission to use the Services and gotten your parent or guardian to agree
    to these Terms on your behalf).
    <br />
    <br />
    If your use of the Services is prohibited by applicable laws, then you
    aren't authorized to use the Services. We can't and won't be responsible for
    your using the Services in a way that breaks the law.
    <br />
    <br />
    You are responsible for protecting the security of your account (e.g., by
    not sharing access to the email account you use for logging in to
    calendarbridge), and you are responsible for any activity associated with
    your account.
    <br />
    <br />
    You represent, warrant, and agree that you will not contribute any Service
    Data or Content (each of those terms is defined below) or otherwise use the
    Services or interact with the Services in a manner that:
    <br />
    <br />
    (a) Infringes or violates the intellectual property rights or any other
    rights of anyone else (including CalendarBridge);
    <br />
    <br />
    (b) Violates any law or regulation, including any applicable export control
    laws;
    <br />
    <br />
    (c) Is harmful, fraudulent, deceptive, threatening, harassing, defamatory,
    obscene, or otherwise objectionable;
    <br />
    <br />
    (d) Jeopardizes the security of your account or anyone else's (such as
    allowing someone else to log in to the Services as you);
    <br />
    <br />
    (e) Attempts, in any manner, to obtain the password, account, or other
    security information from any other user;
    <br />
    <br />
    (f) Violates the security of any computer network, or cracks any passwords
    or security encryption codes;
    <br />
    <br />
    (g) Runs Maillist, Listserv, any form of auto-responder or “spam” on the
    Services, or any processes that run or are activated while you are not
    logged into the Services, or that otherwise interfere with the proper
    working of the Services (including by placing an unreasonable load on the
    Services' infrastructure);
    <br />
    <br />
    (h) “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or
    relating to the Services or Content (through use of manual or automated
    means);
    <br />
    <br />
    (i) Copies or stores any significant portion of the Content;
    <br />
    <br />
    (j) Decompiles, reverse engineers, or otherwise attempts to obtain the
    source code or underlying ideas or information of or relating to the
    Services.
    <br />
    <br />
    A violation of any of the foregoing is grounds for termination of your right
    to use or access the Services.
    <br />
    <br />
    The materials displayed or performed or available on or through the
    Services, including, but not limited to, text, graphics, data, articles,
    photos, images, illustrations, and so forth (all of the foregoing, the
    “Content”) are protected by copyright and/or other intellectual property
    laws. You promise to abide by all copyright notices, trademark rules,
    information, and restrictions contained in any Content you access through
    the Services, and you won't use, copy, reproduce, modify, translate,
    publish, broadcast, transmit, distribute, perform, upload, display, license,
    sell or otherwise exploit for any purpose any Content not owned by you, (i)
    without the prior consent of the owner of that Content or (ii) in a way that
    violates someone else's (including CalendarBridge's) rights.
    <br />
    <br />
    You understand that CalendarBridge owns the Services. You won't modify,
    publish, transmit, participate in the transfer or sale of, reproduce (except
    as expressly provided in this Section), create derivative works based on, or
    otherwise exploit any of the Services.
    <br />
    <br />
    Any information or content that you provide through the Services is your
    “Service Data.” Some Service Data (e.g. scheduling links) are viewable by
    other users. In order to display your Service Data on the Services, and to
    allow other users to view them (where applicable), you grant us certain
    rights in that Service Data. Please note that all of the following licenses
    are subject to our Privacy Policy (attached hereto as Appendix A) to the
    extent they relate to Service Data that are also your
    personally-identifiable information.
    <br />
    <br />
    For all Service Data, you hereby grant CalendarBridge a license to
    translate, modify (for technical purposes, for example making sure your
    content is viewable on an iPhone as well as a computer) and reproduce and
    otherwise act with respect to such Service Data, in each case to enable us
    to operate the Services, as described in more detail below. This is a
    license only - your ownership in Service Data is not affected.
    <br />
    <br />
    If you share Service Data only in a manner that only certain specified users
    can view (for example, the user with whom you are scheduling a meeting)
    (“Limited Audience Service Data”), then you grant CalendarBridge the
    licenses above, as well as a license to display, perform, and distribute
    your Limited Audience Service Data for the sole purpose of making that
    Limited Audience Service Data accessible to such other specified users, and
    providing the Services necessary to do so. Also, you grant such other
    specified users a license to access that Limited Audience Service Data, and
    to use and exercise all rights in it, as permitted by the functionality of
    the Services.
    <br />
    <br />
    If you provide us (in a direct email or otherwise) with any feedback,
    suggestions, improvements, enhancements, and/or feature requests relating to
    the Services (“Feedback”), then you grant CalendarBridge the licenses above,
    as well as a license to use, display, perform, and distribute your Feedback
    in connection with CalendarBridge's business.
    <br />
    <br />
    You agree that the licenses you grant are royalty-free, perpetual,
    sublicenseable, irrevocable, and worldwide, provided that when you delete
    your account or remove CalendarBridge's access to your Connected Account, we
    will stop displaying your Service Data (e.g. your scheduling preferences and
    scheduling links) to other users (if applicable), but you understand and
    agree that it may not be possible to completely delete that content from
    CalendarBridge's records, and that your Service Data may remain viewable
    elsewhere to the extent that they were copied or stored by other users.
    <br />
    <br />
    Finally, you understand and agree that CalendarBridge, in performing the
    required technical steps to provide the Services to our users (including
    you), may need to make changes to your Service Data to conform and adapt
    your Service Data to the technical requirements of connection networks,
    devices, services, or media, and the foregoing licenses include the rights
    to do so.
    <br />
    <br />
    Any information or content publicly posted or privately transmitted through
    the Services is the sole responsibility of the person from whom such content
    originated, and you access all such information and content at your own
    risk, and we aren't liable for any errors or omissions in that information
    or content or for any damages or loss you might suffer in connection with
    it. We cannot control and have no duty to take any action regarding how you
    may interpret and use the Content or what actions you may take as a result
    of having been exposed to the Content, and you hereby release us from all
    liability for you having acquired or not acquired Content through the
    Services. We can't guarantee the identity of any users with whom you
    interact in using the Services and are not responsible for which users gain
    access to the Services.
    <br />
    <br />
    You are responsible for all Content you contribute, in any manner, to the
    Services, and you represent and warrant you have all rights necessary to do
    so, in the manner in which you contribute it. You will keep all your
    registration information accurate and current. You are responsible for all
    your activity in connection with the Services.
    <br />
    <br />
    The Services may contain links or connections to third party websites or
    services that are not owned or controlled by CalendarBridge. When you access
    third party websites or use third party services, you accept that there are
    risks in doing so, and that CalendarBridge is not responsible for such
    risks. We encourage you to be aware when you leave the Services and to read
    the terms and conditions and privacy policy of each third-party website or
    service that you visit or utilize.
    <br />
    <br />
    CalendarBridge has no control over, and assumes no responsibility for, the
    content, accuracy, privacy policies, or practices of or opinions expressed
    in any third party websites or by any third party that you interact with
    through the Services. In addition, CalendarBridge will not and cannot
    monitor, verify, censor or edit the content of any third-party site or
    service. By using the Services, you release and hold us harmless from any
    and all liability arising from your use of any third-party website or
    service.
    <br />
    <br />
    Your interactions with organizations and/or individuals found on or through
    the Services, including payment and delivery of goods or services, and any
    other terms, conditions, warranties or representations associated with such
    dealings, are solely between you and such organizations and/or individuals.
    You should make whatever investigation you feel necessary or appropriate
    before proceeding with any online or offline transaction with any of these
    third parties. You agree that CalendarBridge shall not be responsible or
    liable for any loss or damage of any sort incurred as the result of any such
    dealings.
    <br />
    <br />
    If there is a dispute between participants on this site, or between users
    and any third party, you agree that CalendarBridge is under no obligation to
    become involved. In the event that you have a dispute with one or more other
    users, you release CalendarBridge, its officers, employees, agents, and
    successors from claims, demands, and damages of every kind or nature, known
    or unknown, suspected or unsuspected, disclosed or undisclosed, arising out
    of or in any way related to such disputes and/or our Services. If you are a
    California resident, you shall and hereby do waive California Civil Code
    Section 1542, which says: "A general release does not extend to claims which
    the creditor does not know or suspect to exist in his or her favor at the
    time of executing the release, which, if known by him or her must have
    materially affected his or her settlement with the debtor."
    <br />
    <br />
    <b>Changes to the Services</b>
    <br />
    <br />
    We may suspend or discontinue any part of the Services, or we may introduce
    new features or impose limits on certain features or restrict access to
    parts or all of the Services. We'll try to give you notice when we make a
    material change to the Services that would adversely affect you, but this
    isn't always practical. Similarly, we reserve the right to remove any
    Content from the Services at any time, for any reason (including, but not
    limited to, if someone alleges you contributed that Content in violation of
    these Terms), in our sole discretion, and without notice.
    <br />
    <br />
    <b>Billing</b>
    <br />
    <br />
    Certain portions of the Services are currently free, but we reserve the
    right to charge for other portions or all Services in the future. We will
    notify you before any Services you are then using begin carrying a fee, and
    if you wish to continue using such Services, you must pay all applicable
    fees for such Services. If you choose to upgrade to a paid version of the
    Services, then you will pay the applicable fees set forth during the
    subscription process. The subscription fee for the paid version (the
    “Subscription Fee”) will be charged to you in advance, to the credit card
    you provided during the subscription process. <br />
    <br />
    You may cancel a subscription at any time to prevent the subscription from
    renewing at the end of the then-current billing cycle. Subscription Fees are
    non-refundable.
    <br />
    <br />
    Your subscription will automatically renew in accordance with the billing
    plan that you selected. CalendarBridge may change the Subscription Fee upon
    notice to you, but such change will only take effect once your then-current
    subscription term has ended. If you do not wish to pay the new Subscription
    Fee, your only remedy shall be to cancel or downgrade your subscription.{' '}
    <br />
    <br />
    CalendarBridge is also free to terminate (or suspend access to) your use of
    the Services, for any reason in our discretion, including your breach of
    these Terms. CalendarBridge has the sole right to decide whether you are in
    violation of any of the restrictions set forth in these Terms.
    <br />
    <br />
    Termination of your account may result in destruction of any Content
    associated with your account, so keep that in mind before you decide to
    terminate your account or remove access to your Connected Account. We will
    try to provide advance notice to you prior to our terminating your access to
    the Services so that you are able to retrieve any important Service Data
    (e.g. scheduling preferences) (to the extent allowed by law and these
    Terms), but we may not do so if we determine it would be impractical,
    illegal, not in the interest of someone's safety or security, or otherwise
    harmful to the rights or property of CalendarBridge.
    <br />
    <br />
    Provisions that, by their nature, should survive termination of these Terms
    shall survive termination. By way of example, all of the following will
    survive termination: any obligation you have to pay us or indemnify us, any
    limitations on our liability, any terms regarding ownership or intellectual
    property rights, and terms regarding disputes between us.
    <br />
    <br />
    <b>Other Terms</b>
    <br />
    <br />
    <em>Warranty Disclaimer. </em> Neither CalendarBridge nor its licensors or
    suppliers makes any representations or warranties concerning any content
    contained in or accessed through the Services, and we will not be
    responsible or liable for the accuracy, copyright compliance, legality, or
    decency of material contained in or accessed through the Services. We (and
    our licensors and suppliers) make no representations or warranties regarding
    suggestions or recommendations of services or products offered or purchased
    through the Services.{' '}
    <b>
      THE SERVICES AND CONTENT ARE PROVIDED BY CALENDARBRIDGE (AND ITS LICENSORS
      AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER
      EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON¬INFRINGEMENT, OR
      THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR- FREE. SOME STATES
      DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE
      ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
    </b>
    <br />
    <br />
    <b>
      <em>Limitation of Liability. </em> TO THE FULLEST EXTENT ALLOWED BY
      APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY
      (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR
      OTHERWISE) SHALL CALENDARBRIGE (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE
      TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL,
      OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS,
      LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE
      OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF $100, OR
      (C) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE
      EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND
      EXCLUSIONS MAY NOT APPLY TO YOU.
    </b>
    <br />
    <br />
    <em>Indemnity. </em> To the fullest extent allowed by applicable law, You
    agree to indemnify and hold CalendarBridge, its affiliates, officers,
    agents, employees, and partners harmless from and against any and all
    claims, liabilities, damages (actual and consequential), losses and expenses
    (including attorneys' fees) arising from or in any way related to any third
    party claims relating to (a) your use of the Services (including any actions
    taken by a third party using your account), and (b) your violation of these
    Terms. In the event of such a claim, suit, or action (“Claim”), we will
    attempt to provide notice of the Claim to the contact information we have
    for your account (provided that failure to deliver such notice shall not
    eliminate or reduce your indemnification obligations hereunder).
    <br />
    <br />
    <em>Assignment. </em> You may not assign, delegate or transfer these Terms
    or your rights or obligations hereunder, or your account, in any way (by
    operation of law or otherwise) without CalendarBridge's prior written
    consent. We may transfer, assign, or delegate these Terms and our rights and
    obligations without consent.
    <br />
    <br />
    <em>Choice of Law; Arbitration. </em> These Terms are governed by and will
    be construed under the laws of the State of Delaware, without regard to the
    conflicts of laws provisions thereof. Any dispute arising from or relating
    to the subject matter of these Terms shall be finally settled in Chicago,
    Illinois, in English, in accordance with the Streamlined Arbitration Rules
    and Procedures of Judicial Arbitration and Mediation Services, Inc. ("JAMS")
    then in effect, by one commercial arbitrator with substantial experience in
    resolving intellectual property and commercial contract disputes, who shall
    be selected from the appropriate list of JAMS arbitrators in accordance with
    such Rules. Judgment upon the award rendered by such arbitrator may be
    entered in any court of competent jurisdiction. Notwithstanding the
    foregoing obligation to arbitrate disputes, each party shall have the right
    to pursue injunctive or other equitable relief at any time, from any court
    of competent jurisdiction. For all purposes of these Terms, the parties
    consent to exclusive jurisdiction and venue in the state or federal courts
    located in, respectively, Chicago, Illinois, or the Northern District of
    Illinois.{' '}
    <b>
      Any arbitration under these Terms will take place on an individual basis:
      class arbitrations and class actions are not permitted.
    </b>
    <br />
    <br />
    <b>
      YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE TERMS, YOU AND
      CALENDARBRIDGE ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO
      PARTICIPATE IN A CLASS ACTION.
    </b>
    <br />
    <br />
    <em>Miscellaneous</em>You will be responsible for paying, withholding,
    filing, and reporting all taxes, duties, and other governmental assessments
    associated with your activity in connection with the Services, provided that
    the CalendarBridge may, in its sole discretion, do any of the foregoing on
    your behalf or for itself as it sees fit. The failure of either you or us to
    exercise, in any way, any right herein shall not be deemed a waiver of any
    further rights hereunder. If any provision of these Terms is found to be
    unenforceable or invalid, that provision will be limited or eliminated, to
    the minimum extent necessary, so that these Terms shall otherwise remain in
    full force and effect and enforceable. You and CalendarBridge agree that
    these Terms are the complete and exclusive statement of the mutual
    understanding between you and CalendarBridge, and that it supersedes and
    cancels all previous written and oral agreements,
    <br />
    <br />
    communications and other understandings relating to the subject matter of
    these Terms. You hereby acknowledge and agree that you are not an employee,
    agent, partner, or joint venture of CalendarBridge, and you do not have any
    authority of any kind to bind CalendarBridge in any respect whatsoever.
    <br />
    <br />
     <h2>Appendix A – CalendarBridge Privacy Policy</h2>
    <b>
      By using or accessing the Services in any manner, you acknowledge that you
      accept the practices and policies outlined in this Privacy Policy, and you
      hereby consent that we will collect, use, and share your information in
      the following ways.{' '}
    </b>
    <br />
    <br />
    Remember that your use of CalendarBridges Services is at all times subject
    to the Terms of Use, which incorporates this Privacy Policy. Any terms we
    use in this Policy without defining them have the definitions given to them
    in the Terms of Use. If you have any questions or concerns regarding our
    privacy policies, please send a detailed message to
    privacy@calendarbridge.com and we will try to resolve your concerns.
    <br />
    <br />
    <b>Overview</b>
    <br />
    <br />
    This Privacy Policy covers our treatment of personally identifiable
    information ("Personal Information") that we gather when you are accessing
    or using our Services, but not to the practices of companies we don't own or
    control, or people that we don't manage. We gather various types of Personal
    Information from our users, as explained in more detail below, and we use
    this Personal Information internally in connection with our Services,
    including to personalize, provide, and improve our services, to allow you to
    set up your account, to contact you and allow other users to contact you, to
    fulfill your requests for certain products and services, and to analyze how
    you use the Services. In certain cases, we may also share some Personal
    Information with third parties, but only as described below.
    <br />
    <br />
    As noted in the Terms of Use, we do not knowingly collect or solicit
    personal information from anyone under the age of 16. If you are under 16,
    please do not attempt to register for the Services or send any personal
    information about yourself to us. If we learn that we have collected
    personal information from a child under age 16, we will delete that
    information as quickly as possible. If you believe that a child under 16 may
    have provided us personal information, please contact us at
    info@calendarbridge.com.
    <br />
    <br />
    <b>Changes to this Privacy Policy</b>
    <br />
    <br />
    As our services or the law change, we may need to change this Privacy Policy
    from time to time, but we will alert you to changes by placing a notice on
    calendarbridge.com, by sending you an email, and/or by some other means.
    Please note that if you've opted not to receive legal notice emails from us
    (or you haven't provided us with your email address), those legal notices
    will still govern your use of the Services, and you are still responsible
    for reading and understanding them. If you use the Services after any
    changes to the Privacy Policy have been posted, that means you agree to all
    of the changes.
    <br />
    <br />
    <b>Information that CalendarBridge Collects</b>
    <br />
    <br />
    <em>Information You Provide to Us. </em> We receive and store any
    information you knowingly provide to us. For example, we may receive or
    collect Personal Information such as your name, email address, and Connected
    Account credentials (although for many third-party services that support the
    OAuth protocol, CalendarBridge does not store or see those credentials. This
    includes, for example, Microsoft and Google accounts when using "Sign in
    with Google" and "Sign in with Microsoft" features). If you provide your
    Connected Account credentials to us or otherwise sign in to the Services
    through a third party site or service, you understand some content and/or
    information in those Connected Accounts (“Third Party Account Information”)
    may be transmitted to us, and that Third Party Account Information
    transmitted to our Services is covered by this Privacy Policy; for example,
    your list of contacts and calendar information. Certain information may be
    required to register with us or to take advantage of some of our features.
    <br />
    <br />
    We may communicate with you if you've provided us the means to do so. For
    example, if you've given us your email address, we may send you promotional
    email offers on behalf of other businesses, or email you about your use of
    the Services. Also, we may receive a confirmation when you open an email
    from us. This confirmation helps us make our communications with you more
    interesting and improve our services. If you do not want to receive
    communications from us, please indicate your preference by using the
    “unsubscribe” link at the bottom of our emails.
    <br />
    <br />
    <em>Information Collected Automatically. </em> Whenever you interact with
    our Services, we automatically receive and record information on our server
    logs from your browser or device, which may include your IP address,
    geolocation data, device identification, “cookie” information, the type of
    browser and/or device you're using to access our Services, and the page or
    feature you requested. “Cookies” are identifiers we transfer to your browser
    or device that allow us to recognize your browser or device and tell us how
    and when pages and features in our Services are visited and by how many
    people. You may be able to change the preferences on your browser or device
    to prevent or limit your device's acceptance of cookies, but this may
    prevent you from taking advantage of some of our features.
    <br />
    <br />
    If you click on a link to a third-party website or service, a third-party
    may also transmit cookies to you. Again, this Privacy Policy does not cover
    the use of cookies by any third parties, and we aren't responsible for their
    privacy policies and practices. Please be aware that cookies placed by third
    parties may continue to track your activities online even after you have
    left our Services, and those third parties may not honor “Do Not Track”
    requests you have set using your browser or device.
    <br />
    <br />
    We may use this data to customize content for you that we think you might
    like, based on your usage patterns. We may also use it to improve the
    Services - for example, this data can tell us how often users use a
    particular feature of the Services, and we can use that knowledge to make
    the Services interesting to as many users as possible.
    <br />
    <br />
    <b>Sharing of Personal Information</b>
    <br />
    <br />
    We do not rent or sell your Personal Information in personally identifiable
    form to anyone. We may share your Personal Information with third parties as
    described in this section:
    <br />
    <br />
    <em>Information that's been de-identified. </em>We may de-identify your
    Personal Information so that you are not identified as an individual, and
    provide that information to our partners. We may also provide aggregate
    usage information to our partners (or allow partners to collect that
    information from you), who may use such information to understand how often
    and in what ways people use our Services, so that they, too, can provide you
    with an optimal online experience. However, we never disclose aggregate
    usage or de-identified information to a partner (or allow a partner to
    collect such information) in a manner that would identify you as an
    individual person.
    <br />
    <br />
    <em>Affiliated Businesses. </em> In certain situations, businesses or
    third-party websites we're affiliated with may provide products or services
    to you through or in connection with the Services (either alone or jointly
    with us). You can recognize when an affiliated business is associated with
    such a transaction or service, and we will share your Personal Information
    with that affiliated business only to the extent that it is related to such
    transaction or service. We have no control over the policies and practices
    of third-party websites or businesses as to privacy or anything else, so if
    you choose to take part in any transaction or service relating to an
    affiliated website or business, please review all such business' or
    websites' policies.
    <br />
    <br />
    <em>Agents. </em>We employ other companies and people to perform tasks on
    our behalf and need to share your information with them to provide products
    or services to you; for example, we use a payment processing company to
    receive and process your credit card transactions for us. Unless we tell you
    differently, our agents do not have any right to use the Personal
    Information we share with them beyond what is necessary to assist us. Note
    that an “agent” may also be considered a “partner” in certain circumstances,
    and would be subject to the terms of the “Information that's been
    de-identified” section in that regard.
    <br />
    <br />
    <em>User Profiles and Submissions. </em>Certain user profile information,
    including your name, location, and any video or image content relating to
    the Connected Account that you use in connection with the Services, may be
    displayed to other users to facilitate user interaction within the Services
    or address your request for our services.
    <br />
    <br />
    <em>Business Transfers. </em> We may choose to buy or sell assets, and may
    share and/or transfer customer information in connection with the evaluation
    of and entry into such transactions. Also, if we (or our assets) are
    acquired, or if we go out of business, enter bankruptcy, or go through some
    other change of control, Personal Information could be one of the assets
    transferred to or acquired by a third party.
    <br />
    <br />
    <em>Protection of CalendarBridge and Others. </em> We reserve the right to
    access, read, preserve, and disclose any information that we believe is
    necessary to comply with law or court order; enforce or apply our Terms of
    Use and other agreements; or protect the rights, property, or safety of
    CalendarBridge, our employees, our users, or others.
    <br />
    <br />
    <b>Security of Personal Information</b>
    <br />
    <br />
    Your account is protected by a password for your privacy and security. If
    you access the Services via a third-party site or service, you may have
    additional or different sign-on protections via that third-party site or
    service. You must prevent unauthorized access to your account and Personal
    Information by selecting and protecting your password and/or other sign-on
    mechanism appropriately and limiting access to your computer or device and
    browser by signing off after you have finished accessing your account. We
    endeavor to protect the privacy of your Personal Information we hold in our
    records, but unfortunately, we cannot guarantee complete security.
    Unauthorized entry or use, hardware or software failure, and other factors,
    may compromise the security of user information at any time.
    <br />
    <br />
    <b>What Personal Information can I access? </b>
    <br />
    <br />
    Through your account settings, you may access, and, in some cases, edit or
    delete the following information you've provided to us:
    <br />
    <br />
    • Name
    <br />
    <br />
    • Email address
    <br />
    <br />
    • Username
    <br />
    <br />
    • Connected Account settings
    <br />
    <br />
    • Scheduling preferences and links
    <br />
    <br />
    The information you can view, update, and delete may also depend on the
    Connected Account you use and the permissions that you grant through your
    Connected Account, and may change as the Services change. If you have any
    questions about viewing or updating information we have on file about you,
    please contact us at info@calendarbridge.com.
    <br />
    <br />
    California residents are entitled to ask us for a notice identifying the
    categories of Personal Information which we share with our affiliates and/or
    third parties for marketing purposes, and providing contact information for
    such affiliates and/or third parties. If you are a California resident and
    would like a copy of this notice, please submit a written request to:
    info@calendarbridge.com.
    <br />
    <br />
    <b>Controlling Your Personal Information</b>
    <br />
    <br />
    You can always opt not to disclose information to us, but keep in mind some
    information may be needed to take advantage of some of our features.
    <br />
    <br />
    You may be able to add, update, or delete information as explained above.
    When you update information, however, we may maintain a copy of the
    unrevised information in our records. Some information may remain in our
    records after cease using the Services. We may use any aggregated data
    derived from or incorporating your Personal Information after you update or
    delete it, but not in a manner that would identify you personally.
    <br />
    <br />
    <b>EU Residents</b>
    <br />
    <br />
    If you are a resident of the European Union (“EU”), United Kingdom,
    Lichtenstein, Norway, or Iceland, you may have additional rights under the
    EU General Data Protection Regulation (the “GDPR”) with respect to your
    Personal Data, as outlined below.
    <br />
    <br />
    For this section, we use the terms “Personal Data” and “processing” as they
    are defined in the GDPR, but “Personal Data” generally means information
    that can be used to individually identify a person, and “processing”
    generally covers actions that can be performed in connection with data such
    as collection, use, storage and disclosure. If CalendarBridge is not
    processing your Personal Data on behalf of a third party, CalendarBridge
    will be the controller of your Personal Data processed in connection with
    the Services.
    <br />
    <br />
    If there are any conflicts between this section and any other provision of
    this Privacy Policy, the policy or portion that is more protective of
    Personal Data shall control to the extent of such conflict. Category of Data
    Source of Data Purpose of
    <br />
    <br />
    <table>
      <tr>
        <td>
          <p>
            <b>
              <span>Category of Data</span>
            </b>
          </p>
        </td>
        <td>
          <p>
            <b>
              <span>Source of Data </span>
            </b>
          </p>
        </td>
        <td>
          <p>
            <b>
              <span>Purpose of</span>
            </b>
          </p>
          <p>
            <b>
              <span>Processing</span>
            </b>
          </p>
        </td>
        <td>
          <p>
            <b>
              <span>Grounds for Processing</span>
            </b>
          </p>
        </td>
        <td>
          <p>
            <b>
              <span>Specific Legitimate Interest </span>
            </b>
            <i>
              <span>(if applicable)</span>
            </i>
          </p>
        </td>
        <td>
          <p>
            <b>
              <span>Retention Period of Such Data</span>
            </b>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span>
              Customer Contact Data (Name, Mailing Address, Email Address, Phone
              Number)
            </span>
          </p>
        </td>
        <td>
          <p>
            <span>Data Subject</span>
          </p>
        </td>
        <td>
          <p>
            <span>To provide the Services to you.</span>
          </p>
        </td>
        <td>
          <p>
            <span>Legitimate Interests</span>
          </p>
        </td>
        <td>
          <p>
            <span>
              To protect the security of your account, to communicate with you
              about the Services, and to allow you to access the functionality
              of the Services.
            </span>
          </p>
        </td>
        <td>
          <p>
            <span>As long as your account is considered to be active.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span>Scheduling Preferences and Links</span>
          </p>
        </td>
        <td>
          <p>
            <span>Data Subject</span>
          </p>
        </td>
        <td>
          <p>
            <span>To provide the Services to you.</span>
          </p>
        </td>
        <td>
          <p>
            <span>Legitimate Interests</span>
          </p>
        </td>
        <td>
          <p>
            <span>
              To allow you to schedule and manage events with third parties.
            </span>
          </p>
        </td>
        <td>
          <p>
            <span>As long as your account is considered to be active.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span>
              User's Company Profile Data (e.g. company website URL, company
              email address, company product name etc.)
            </span>
          </p>
        </td>
        <td>
          <p>
            <span>Data Subject</span>
          </p>
        </td>
        <td>
          <p>
            <span>
              To provide the API functionality of the Services to you.
            </span>
          </p>
        </td>
        <td>
          <p>
            <span>Legitimate Interests</span>
          </p>
        </td>
        <td>
          <p>
            <span>
              To allow you to integrate the scheduling functionality of the
              Services into your own website or application.
            </span>
          </p>
        </td>
        <td>
          <p>
            <span>As long as your account is considered to be active.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span>Credit Card Information and Billing Address</span>
          </p>
        </td>
        <td>
          <p>
            <span>Data Subject</span>
          </p>
        </td>
        <td>
          <p>
            <span>To provide you with paid access to the Services.</span>
          </p>
        </td>
        <td>
          <p>
            <span>Legitimate</span>
          </p>
          <p>
            <span>Interests</span>
          </p>
        </td>
        <td>
          <p>
            <span>
              To enable you to purchase the paid portions of the Services.
            </span>
          </p>
        </td>
        <td>
          <p>
            <span>As long as your account is considered to be active.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span>Device Information (operating system, web browser etc.)</span>
          </p>
        </td>
        <td>
          <p>
            <span>Data Subject</span>
          </p>
        </td>
        <td>
          <p>
            <span>To provide the Services to you.</span>
          </p>
        </td>
        <td>
          <p>
            <span>Legitimate Interests</span>
          </p>
        </td>
        <td>
          <p>
            <span>
              To allow you to access the functionality of the Services.
            </span>
          </p>
        </td>
        <td>
          <p>
            <span>As long as your account is considered to be active.</span>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>
            <span>IP address</span>
          </p>
        </td>
        <td>
          <p>
            <span>Data Subject</span>
          </p>
        </td>
        <td>
          <p>
            <span>
              To provide the Services   to you (to determine timezone for
              scheduling)
            </span>
          </p>
        </td>
        <td>
          <p>
            <span>Legitimate Interests</span>
          </p>
        </td>
        <td>
          <p>
            <span>
              To allow you to access the functionality of the Services.
            </span>
          </p>
        </td>
        <td>
          <p>
            <span>As long as your account is considered to be active.</span>
          </p>
        </td>
      </tr>
    </table>
    <br />
    <br />
    <b>Data Sharing</b>
    <br />
    <br />
    We share Personal Data with vendors, third-party service providers and
    agents who work on our behalf and provide us with services related to the
    purposes described in this Privacy Policy or our Terms of Service. These
    parties include:
    <br />
    <br />
    • Payment processors (Stripe)
    <br />
    <br />
    • Analytics service providers (Google)
    <br />
    <br />
    • Messaging service providers (PostMark, MailChimp, Hubspot)
    <br />
    <br />
    • Staff augmentation and contract personnel
    <br />
    <br />
    • Hosting service providers
    <br />
    <br />
    We also share Personal Data when necessary to complete a transaction
    initiated or authorized by you or provide you with a product or service you
    have requested. In addition to those set forth above, these parties also
    include:
    <br />
    <br />
    • Other users (where you schedule events with them)
    <br />
    <br />
    • Third party business partners who you access through the Services
    <br />
    <br />
    • Other parties authorized by you
    <br />
    <br />
    We also share information with third parties when you have given us consent
    to do so (as indicated at the point such information is collected).
    <br />
    <br />
    We also share Personal Data when we believe it is necessary to:
    <br />
    <br />
    • Comply with applicable law or respond to valid legal process, including
    from law enforcement or other government agencies
    <br />
    <br />
    • Protect us, our business or our users, for example to enforce our terms of
    service, prevent spam or other unwanted communications, and investigate or
    protect against fraud
    <br />
    <br />
    • Maintain the security of our products and services
    <br />
    <br />
    Furthermore, if we choose to buy or sell assets, user information is
    typically one of the transferred business assets. Moreover, if we, or
    substantially all of our assets, are acquired, or if we go out of business
    or enter bankruptcy, user information would be one of the assets that is
    transferred or acquired by a third party, and we would share Personal Data
    with the party that is acquiring our assets. You acknowledge that such
    transfers may occur, and that any acquirer of us or our assets may continue
    to use your Personal Information as set forth in this policy.
    <br />
    <br />
    <b>Data Retention</b>
    <br />
    <br />
    We retain Personal Data about you for as long as you have an open account
    with us or as otherwise necessary to provide you Services. In some cases, we
    retain Personal Data for longer, if doing so is necessary to comply with our
    legal obligations, resolve disputes or collect fees owed, or is otherwise
    permitted or required by applicable law, rule or regulation. Afterwards, we
    retain some information in a depersonalized or aggregated form but not in a
    way that would identify you personally.
    <br />
    <br />
    <b>Security Measures</b>
    <br />
    <br />
    We seek to protect Personal Data using reasonable technical and
    organizational measures based on the type of Personal Data and applicable
    processing activity. For example, we use encryption when sending and storing
    Personal Data. We do not sell Personal Data to third parties, and only allow
    third-party access to Personal Data in order to improve the Services or for
    the purpose of analytics. We use OAuth to ensure a secure sign up and
    authentication process, SSL connections to manage the storage of Personal
    Data, and firewalls for additional protection of stored Personal Data.
    <br />
    <br />
    <b>Rights regarding your personal data</b>
    <br />
    <br />
    You have certain rights with respect to your Personal Data, including those
    set forth below. For more information about these rights, or to submit a
    request, please email info@calendarbridge.com. Please note that in some
    circumstances, we may not be able to fully comply with your request, such as
    if it is frivolous or extremely impractical, if it jeopardizes the rights of
    others, or if it is not required by law, but in those circumstances, we will
    still respond to notify you of such a decision. In some cases, we may also
    need to you to provide us with additional information, which may include
    Personal Data, if necessary to verify your identity and the nature of your
    request.
    <br />
    <br />
    <em>Access. </em>You can request more information about the Personal Data we
    hold about you and request a copy of such Personal Data. You can also access
    certain of your Personal Data by logging into CalendarBridge and viewing
    your account settings.
    <br />
    <br />
    <em>Rectification. </em> If you believe that any Personal Data we are
    holding about you is incorrect or incomplete, you can request that we
    correct or supplement such data. You can also correct some of this
    information directly by accessing your account settings. You can correct
    some of this information directly by logging into CalendarBridge and editing
    your information from the account settings page.
    <br />
    <br />
    <em>Erasure. </em>You can request that we erase some or all of your Personal
    Data from our systems.
    <br />
    <br />
    <em>Withdrawal of Consent. </em>If we are processing your Personal Data
    based on your consent (as indicated at the time of collection of such data),
    you have the right to withdraw your consent at any time. Please note,
    however, that if you exercise this right, you may have to then provide
    express consent on a case-by-case basis for the use or disclosure of certain
    of your Personal Data, if such use or disclosure is necessary to enable you
    to utilize some or all of our Services. <br />
    <br />
    <em>Portability. </em>You can ask for a copy of your Personal Data in a
    machine-readable format. You can also request that we transmit the data to
    another controller where technically feasible.
    <br />
    <br />
    <em>Objection. </em>You can contact us to let us know that you object to the
    further use or disclosure of your Personal Data for certain purposes.
    <br />
    <br />
    <em>Restriction of Processing. </em>You can ask us to restrict further
    processing of your Personal Data.
    <br />
    <br />
    <em>Right to File Complaint. </em>You have the right to lodge a complaint
    about CalendarBridge's practices with respect to your Personal Data with the
    supervisory authority of your country or EU Member State.
    <br />
    <br />
    <em>Children. </em> We request individuals under 16 not provide personal
    data to CalendarBridge. If we learn that we have collected the personal data
    from a child under 16, we will take steps to delete the information as soon
    as possible.
    <br />
    <br />
    <em>Transfers of Personal Data. </em> The Services are hosted and operated
    in the United States (“U.S.”) through CalendarBridge and its service
    providers, and if you do not reside in the U.S., laws in the U.S. may differ
    from the laws where you reside. By using the Services, you acknowledge that
    any Personal Data about you, regardless of whether provided by you or
    obtained from a third party, is being provided to CalendarBridge in the U.S.
    and will be hosted on U.S. servers, and you authorize CalendarBridge to
    transfer, store and process your information to and in the U.S., and
    possibly other countries. You hereby consent to the transfer of your data to
    the U.S. pursuant to a data processing agreement incorporating standard data
    protection clauses promulgated by the European Commission, a copy of which
    can be obtained at
    https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32021D0914.
    </div>
    </div>
  <WebsiteFooter></WebsiteFooter>
  </div>
)
              }

export default TermsAndPrivacy
